@import-normalize; /* bring in normalize.css styles */

body {
  margin: 0;
  line-height: 1.22;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
div, button, ul, li, a, input, textarea {
  box-sizing: border-box;
}
.no-scroll {
  overflow: hidden;
}
button,
a {
  cursor: pointer;
}
button, input, textarea {
  font: inherit;
}
.text-link {
  color: #084c90;
  font-weight: 700;
}
.text-link:hover,
.text-link:focus {
  text-decoration: underline;
}
input, textarea {
  box-sizing: border-box;
  outline: none;
}